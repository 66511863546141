<template>
  <div class="main-view">

    <h1 class="headline" v-if="!newsForm.id">{{ $t("main.NEWS_TITLE_ADD") }}</h1>
    <h1 class="headline" v-if="newsForm.id">{{ $t("main.NEWS_TITLE_EDIT") }}</h1>

    <div v-if="isLoading">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>

    <div v-if="!isLoading">
      <h2 v-if="newsForm.ids">{{ newsForm.title }}</h2>

      <v-form
        ref="userForm"
        v-model="newsForm.valid"
        :lazy-validation="false"
      >

        <v-text-field
          v-model="newsForm.titleDe"
          counter="100"
          maxlength="100"
          label="Titel (Deutsch)"
          :rules="[rules.required, rules.max100]" />
        <v-text-field
          v-model="newsForm.titleEn"
          counter="100"
          maxlength="100"
          label="Titel (Englisch)"
          :rules="[rules.required, rules.max100]" />

        <v-select :items="newsTypes" v-model="newsForm.newsType" label=""></v-select>

        <div v-if="newsForm.newsType == 'GENERIC'">
          <DatePicker v-model="newsForm.importantUntil" label="Wichtig bis"  :rules="rules.required" />
        </div>

        <div v-if="newsForm.newsType == 'GENERIC' || newsForm.newsType == 'TICKER'">
          <v-select
            :items="countries"
            v-model="newsForm.country"
            label="Land"
            item-value="id"
            item-text="nameShort"
            :rules="rules.required"

            />
        </div>

        Inhalt (Deutsch):
        <quill-editor v-model="newsForm.textDe" />
        <br>
        Inhalt (Englisch):

        <v-btn
          color="secondary"
          class="ml-5 mb-2"
          small
          :loading="isTranslating"
          @click="translateEnglish"
          >DE -&gt; EN</v-btn>
        <quill-editor v-model="newsForm.textEn" />

        <div class="text-right mt-5">
          <v-btn color="primary" :loading="isSaving" @click="saveNewsSignificant" :disabled="!newsForm.valid">Wichtige Änderung</v-btn>
          <v-btn v-if="!isNew" color="primary" class="ml-4" :loading="isSaving" @click="saveNews" :disabled="!newsForm.valid">Korrektur Korrektur</v-btn>
          <v-btn color="secondary" class="ml-4" @click="cancel">Abbrechen</v-btn>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>

import { mapState, mapActions, mapGetters, mapMutations } from "vuex"
import coreService from '@/services/CoreService'
import DatePicker from '@/components/ui/DatePicker'
import moment from "moment"

export default {
  name: 'NewsAdminEdit',
  components: {
    DatePicker
  },
  data() {
    return {
      isSaving: false,
      isTranslating: false,
      newsTypes: [
        {'value': 'GENERIC', 'text': 'Wichtige Neuigkeiten'},
        {'value': 'DESTINATIONMANAGER', 'text': 'DestinationManager'},
        {'value': 'TICKER', 'text': 'Ticker'},
      ],
      newsForm: {
        isValid: false,

        id: null,
        title: '',
        text: '',
        newsType: 'TICKER',
        importantUntil: null,
        country: null
      },
      rules: {
        required: [
          v => !!v || 'Dieses Feld muss gefüllt sein'
        ],
        max100: [
          v => v.length <= 100 || 'Maximal 100 Zeichen erlaubt'
        ],
      },
      newsIsLoading: false,
    }
  },
  mounted: function() {
    this.doNewsFetchAll()
    this.countriesFetchAll(); // no force, will not change often
  },
  computed: {
    ...mapState({
      countries: state => state.countriesStore.entries,
    }),
    ...mapGetters({
      genericNews: 'newsStore/newsWithIsUnread',
      dmNews: 'dmNewsStore/withIsUnread',
      ticker: 'tickerStore/withIsUnread',
    }),
    allNews() {
      const news = [...this.dmNews, ...this.genericNews, ...this.ticker];
      return Array.from(news).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    },
    news() {
      if (this.$route.params.newsId) {
        const article = this.allNews.find(entry => entry.id.toString() === this.$route.params.newsId)
        return article
      } else {
        return null
      }
    },
    isLoading() {
      // new never loads
      if (!this.$route.params.newsId) {
        return false
      }

      return this.newsIsLoading
    },
    isNew() {
      return !this.newsForm.id
    }
  },
  methods: {
    ...mapActions({
      genericNewsFetchAll: "newsStore/fetchAll",
      dmNewsFetchAll: "dmNewsStore/fetchAll",
      tickerFetchAll: 'tickerStore/fetchAll',
      newsCreate: 'newsStore/create',
      newsUpdate: 'newsStore/update',
      countriesFetchAll: "countriesStore/fetchAll"
    }),
    doNewsFetchAll() {
      this.newsIsLoading = true;

      Promise.all([
        this.genericNewsFetchAll({ force: true }),
        this.dmNewsFetchAll({ force: true }),
        this.tickerFetchAll({ force: true }),
      ])
        .then(() => {
          this.newsIsLoading = false;

          if (this.$route.params.newsId && !this.news) {
            this.$router.push({ name: 'news-admin-add' });
          }

          this.updateNewsForm()
        })
        .catch(() => {
          this.newsIsLoading = true;
      });
    },
    updateNewsForm() {
      this.newsForm.id = this.news.id
      this.newsForm.title = this.news.title
      this.newsForm.titleDe = this.news.titleDe
      this.newsForm.titleEn = this.news.titleEn
      this.newsForm.text = this.news.text
      this.newsForm.textDe = this.news.textDe
      this.newsForm.textEn = this.news.textEn
      this.newsForm.newsType = this.news.newsType
      this.newsForm.importantUntil = this.news.importantUntil

      if (this.news.country) {
        this.newsForm.country = this.news.country.id
      }
    },
    gotoList() {
      this.$router.push({name: "news-admin-list"});
    },
    cancel() {
      this.gotoList()
    },
    saveNewsSignificant() {
      this.doSaveNews(true)
    },
    saveNews() {
      this.doSaveNews(false)
    },
    doSaveNews(isSignificantUpdate) {
      this.isSaving = true
      let nowAsIso = moment().format()

      let input = {
        title: this.newsForm.titleDe,
        titleDe: this.newsForm.titleDe,
        titleEn: this.newsForm.titleEn,
        text: this.newsForm.textDe,
        textDe: this.newsForm.textDe,
        textEn: this.newsForm.textEn,
        newsType: this.newsForm.newsType,
        importantUntil: null,
      }
      if (input.newsType == 'GENERIC') {
        input.importantUntil = this.newsForm.importantUntil
        input.country = this.newsForm.country
      }

      if (input.newsType == 'TICKER') {
        input.country = this.newsForm.country
      }

      let promise = null

      let isNew = false
      if (!this.newsForm.id) {
        isNew = true
        isSignificantUpdate = true
      }

      if (isSignificantUpdate) {
        input.significantUpdateAt = nowAsIso
      }

      if (isNew) {
        promise = this.newsCreate({
          variables: {
            input: {
              ...input
            }
          }
        })
      } else {
        promise = this.newsUpdate({
          variables: {
            input: {
              id: this.newsForm.id,
              ...input
            }
          }
        })
      }

      promise.then(() => {
        this.isSaving= false
        this.gotoList()
      }).catch(() => {
        this.isSaving= false
      })

    },
    translateEnglish() {
      this.isTranslating = true
      coreService.translateText('de', 'en', this.newsForm.textDe)
        .then((text) => {
          this.$set(this.newsForm, 'textEn', text)
          this.isTranslating = false
        })
        .catch(() => {
          this.isTranslating = false
        })
    }
  }
}
</script>

<style scoped>
  .main-view {
      padding: 2rem;
      max-width: 1000px;
      margin: auto;
  }
</style>
